import { FloatingMessage, Nav } from '@intility/bifrost-react';
import type { ReactNode } from 'react';
import { Suspense, useContext } from 'react';
import { Link } from 'react-router-dom';

import { DemoContext, DemoModeProvider } from '@/context/DemoContext';
import { DrawerProvider } from '@/context/DrawerContext';
import { ModalProvider } from '@/context/ModalContext';
import { TermsValidator, UserRoleValidator } from '@/features/terms-of-service/components';
import { routes } from '@/routes/config';
import { FullScreenLoader } from '../FullscreenLoader/FullscreenLoader';
import { BottomBar } from './BottomBar';
import { SideNav } from './SideNav';
import { TopNav } from './TopNav';

interface NavBarProps {
  children: ReactNode;
}

const NavBar = ({ children }: NavBarProps) => {
  const { isDemoMode } = useContext(DemoContext);

  return (
    <Nav
      className='h-full'
      logo={
        <Link to={routes.overview.path}>
          <Nav.Logo
            logo={
              <>
                <img src={new URL('/sim_logo.svg', import.meta.url).href} alt={''} />
              </>
            }
          >
            {`Intility SIM ${isDemoMode ? '- Presentasjonvisning' : ''}`}
          </Nav.Logo>
        </Link>
      }
      top={<TopNav />}
      side={<SideNav />}
    >
      <Suspense fallback={<FullScreenLoader />}>
        <DrawerProvider>
          {/* <div className={'hidden md:block'}>
            <UserFeedback />
          </div> */}

          {/* <NewVersionModal /> */}

          {children}
          <BottomBar />
        </DrawerProvider>
      </Suspense>
    </Nav>
  );
};

type MainLayoutProps = {
  children: ReactNode;
};

export const MainLayout = ({ children }: MainLayoutProps) => {
  return (
    <FloatingMessage className='h-full'>
      <DemoModeProvider>
        <ModalProvider>
          <UserRoleValidator>
            <TermsValidator>
              <NavBar>{children}</NavBar>
            </TermsValidator>
          </UserRoleValidator>
        </ModalProvider>
      </DemoModeProvider>
    </FloatingMessage>
  );
};
